import * as React from 'react'
import * as Images from "../components/images"
import Navigation from "../components/navigation";
import {} from "../css/base.css";
import {} from "../css/main.css";
import * as Utils from "../components/utils";
import {StaticImage} from 'gatsby-plugin-image'
import {MdMenu} from "react-icons/md";
import {useRef} from "react";
import {graphql, useStaticQuery} from "gatsby";
import {Helmat} from "../components/utils";

class IndexPage extends React.Component {

    render() {
        return (
            <main>
                <Helmat/>

                <div className="flex flexv wdth100 ht100">
                <img className="dev-logo" src={Images.wordmark_straight} alt="Rohan Ashik Word mark logo"/>
                </div>

            </main>
        )
    }
}


export default IndexPage
